html,
#root {
  background: url("../assets/bg.png") no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.bg-full {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.loading {
  width: 40%;
  background-color: white;
  margin: 15% auto;
}

.azul-logo {
  color: #253570;
}

.azul-bg {
  background-color: #253570;
}

.vermelho-logo {
  color: "#c12a21";
}

.vermelho-bg {
  background-color: #c12a21;
}

.justify {
  text-align: justify;
}
.indent {
  text-indent: 30px;
}

@media print {
  @page {
    margin: 2;
    padding: 2;
    border: 0;
    overflow: hidden;
    size: portrait;
  }

  .print {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }

  .p-height {
    height: 100vh;
  }

  .printRecibo {
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }
}

.pointer {
  cursor: pointer;
}

.dark,
.dark:hover {
  text-decoration: none;
  color: black;
  text-align: start;
  cursor: pointer;
}

.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.recuo {
  text-indent: 1.5em;
}
